import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;

  & .bandana-select__control {
    color: #001c72 !important;
    background-color: #eef0f7 !important;
  }
  & .bandana-select__menu {
    color: #001c72 !important;
    background-color: #eef0f7 !important;
  }
`;

export const Label = styled.div`
  margin-bottom: 5px;
  & > span {
    font-weight: bold;
    color: #bc2e3e;
  }
`;

export const OptionImage = styled.img`
  width: 20px;
  margin-right: 5px;
  position: absolute;
`;

export const OptionLabel = styled.span`
  margin-left: 25px;
  margin-top: 3px;
  display: block;
`;
